import React from "react";
import useTranslations from "../../../components/useTranslations";
import Footer from "../../../components/Footer/Footer";
import InformationLeftNav from "../../../components/TwoColumnLeftNav/NewsLeftNav";
import {graphql} from "gatsby";
import LocalizedLink from "../../../components/localizedLink";

const NewsProducts = ({data: {allMdx}}) => {
    // useTranslations is aware of the global context (and therefore also "locale")
    // so it'll automatically give back the right translations
    const {products} = useTranslations()
    return (
        <>
            <article className="news-page two-column-left-nav">
                <InformationLeftNav/>
                <section className="main-content">
                    <div className="content-container">
                        <h2 className={'large bold'}>{products}</h2>
                        <ul className="post-list flex between wrap">
                            {allMdx.edges.map(({node: post}) => (
                                <LocalizedLink className={`news-link`} to={`/${post.parent.relativeDirectory}`}>
                                    <li key={`${post.frontmatter.title}-${post.fields.locale}`}>
                                        <p className="date">{post.frontmatter.date}</p>
                                        <p className="post-title">{post.frontmatter.title}</p>
                                    </li>
                                </LocalizedLink>
                            ))}
                        </ul>
                    </div>
                </section>
            </article>
            <Footer/>
        </>
    );
};

export default NewsProducts;

export const query = graphql`
  query newsProducts($locale: String!, $dateFormat: String!) {
    allMdx(
      filter: {fileAbsolutePath: {regex: "/(blog)/.*\\\\.mdx$/"}, frontmatter: {tag: {regex: "/(product)/"}}, fields: { locale: { eq: $locale } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: $dateFormat)
            featuredImage
            tag
          }
          fields {
            locale
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
        }
      }
    }
  }
`
